import { navigate, useParams } from '@reach/router';
import { Link } from '@reach/router';
import { i18n } from 'app/components/i18n/i18n';
import * as Yup from 'yup';
import { useSession } from 'app/hooks/useSession';
import { useFormik } from 'formik/dist';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Select from 'react-select';
import { validateCPF } from 'app/utils/validateCPF';
import PasswordStrength from '../PasswordStrength';

export function SignInEtipi() {
  const { t } = useTranslation();
  const client = useClient();
  const params = useParams();
  const { link } = params;
  const { session, signIn } = useSession();
  const appName = process.env.REACT_APP_NAME;

  const [showPassword, setShowPassword] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [states, setStates] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [loadingMunicipalities, setLoadingMunicipalities] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(true);
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState('');
  const [showForm, setShowForm] = useState(appName === 'encceja');
  const [singleClassroom, setSingleClassroom] = useState(false);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordIsStrong, setPasswordIsStrong] = useState(false);
  const passwordRef = useRef();
  const [raDisabled, setRaDisabled] = useState(false);
  const [cpfDisabled, setCpfDisabled] = useState(false);

  const logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const backimage = appName === 'canaleducacao' || appName === 'caboverde' ? require(`app/images/${appName}/login-bg.png`) : require(`app/images/login-bg.jpg`);
  const institutionsRadioString = ['Já sou formado', 'Sem escola'];
  const institutionsRadioButton = institutions?.filter(item => institutionsRadioString.includes(item.name));
  const isStudentEnrolled = radio === 'enrolled';
  const isStudentNoSchool = radio === 'Sem escola';
  const filteredInstitutions = institutions?.filter(item => item.name !== 'Já sou formado' && item.name !== 'Sem escola');
  const cpfRequired = process.env.REACT_APP_REQUIRED_CPF;

  const schema = Yup.object({
    appName: Yup.string(),
    institution: Yup.mixed().when('appName', {
      is: 'encceja',
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required(t('warning.requiredField'))
    }),
    cpfCnpj: Yup.string().when('cpfRequired', {
      is: 'true',
      then: Yup.string()
        .required(t('warning.requiredField'))
        .test('validCPF', 'CPF Inválido', value => validateCPF(value)),
      otherwise: Yup.string().notRequired()
    }),
    ra: Yup.string().nullable().notRequired(),
    name: Yup.string().required(t('warning.requiredField')),
    email: Yup.string().required(t('warning.requiredField')),
    phone: Yup.string()
      .nullable()
      .notRequired()
      .test('phone-length', 'Telefone deve conter 11 caracteres', value => {
        const phone = value?.replace(/[()\s-_]/g, '');
        return !value || phone.length === 11;
      }),
    classroom_ids: Yup.string().required(t('warning.requiredField')),
    password: Yup.string().required(t('warning.requiredField')),
    course: Yup.string().when('appName', {
      is: 'encceja',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(t('warning.requiredField'))
    }),
    'municipality-id': Yup.mixed().when('appName', {
      is: 'encceja',
      then: Yup.mixed().required(t('warning.requiredField')),
      otherwise: Yup.mixed().notRequired()
    }),
    selectedState: Yup.mixed().when('appName', {
      is: 'encceja',
      then: Yup.mixed().required(t('warning.requiredField')),
      otherwise: Yup.mixed().notRequired()
    }),
    'is-enrolled-in-seduc': Yup.boolean().notRequired()
  });

  const authenticateUser = async () => {
    const requestParams = { ...studentForm.values };
    requestParams.classroom_ids = [requestParams.classroom_ids];
    requestParams['municipality-id'] = requestParams['municipality-id'].value;
    delete requestParams.course;
    delete requestParams.institution;
    delete requestParams.ra;
    delete requestParams.selectedState;
    delete requestParams.appName;
    delete requestParams.cpfRequired;
    const { error } = await client.mutate('users', requestParams);

    if (error) {
      toast.error(error.title);
    } else {
      toast.success('Conta criada com sucesso!');
      signIn(studentForm.values);
    }
  };

  const studentForm = useFormik({
    initialValues: {
      'selected-profile-name': 'Aluno',
      classroom_ids: '',
      cpfCnpj: '',
      ra: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      link_key: link,
      institution: '',
      course: '',
      appName: appName,
      cpfRequired: cpfRequired,
      'municipality-id': '',
      'is-enrolled-in-seduc': false,
      selectedState: ''
    },
    validationSchema: schema,
    onSubmit: authenticateUser
  });

  const InfoIcon = () => {
    return (
      <div style={{ background: 'var(--brand-semantic-alerta, #FFD661)', maxWidth: 32, width: '100%', height: 32, borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <svg
          width="2"
          height="22"
          viewBox="0 0 2 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16992 1L1.16992 12.9035"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.83981 16.9386C1.83981 17.1783 1.59917 17.4737 1.1699 17.4737C0.740632 17.4737 0.5 17.1783 0.5 16.9386C0.5 16.6989 0.740632 16.4035 1.1699 16.4035C1.59917 16.4035 1.83981 16.6989 1.83981 16.9386Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </div>
    );
  };

  const updateUser = async () => {
    const requestParams = {
      link_key: studentForm.values.link_key
    };
    const { error } = await client.mutate(`users/${session.user.id}`, requestParams);
    if (error) {
      toast.error('Erro ao aceitar o convite.');
    } else {
      toast.success('Convite aceito com sucesso!');
      navigate('/');
    }
  };

  const handleBlurCPF = async () => {
    if (validateCPF(studentForm.values.cpfCnpj)) {
      // setLoading(true);
      toast.loading('Carregando', {
        id: 'toast-loading'
      });
      const { data } = await client.mutate('canaleducacao/alunos', { cpf: studentForm.values.cpfCnpj });
      if (data?.mensagem === 'Nenhum resultado encontrado!') {
        const userCpf = studentForm.values.cpfCnpj;
        studentForm.setFieldValue('cpfCnpj', userCpf);
        toast.error('Usuário não encontrado. Faça o cadastro e acesse a plataforma', {
          position: 'top-center',
          style: {
            fontWeight: 'bold',
            color: 'var(--brand-primary-dark, #110B3E)'
          },
          icon: <InfoIcon />
        });
      } else {
        fillETIPIFields(data);
        toast.success('Cadastro encontrado. Crie uma senha', {
          position: 'top-center'
        });
      }
      toast.dismiss('toast-loading');
      setLoading(false);
      setShowForm(true);
      if (!radio) {
        setRadio('enrolled');
      }
    } else if (studentForm?.values?.cpfCnpj?.replaceAll('_', '').length === 14 && !validateCPF(studentForm.values.cpfCnpj)) {
      toast.error('CPF inválido');
    }
  };

  const handleBlurRA = async () => {
    if (studentForm.values.ra?.replaceAll('_', '').length === 11) {
      toast.loading('Carregando', {
        id: 'toast-loading'
      });
      const { data } = await client.mutate('canaleducacao/alunos', { ra: studentForm.values.ra });
      if (data?.mensagem === 'Nenhum resultado encontrado!') {
        toast.error('Usuário não encontrado. Faça o cadastro e acesse a plataforma', {
          position: 'top-center',
          style: {
            fontWeight: 'bold',
            color: 'var(--brand-primary-dark, #110B3E)'
          },
          icon: <InfoIcon />
        });
      } else {
        fillETIPIFields(data);
        toast.success('Cadastro encontrado. Crie uma senha', {
          position: 'top-center'
        });
      }

      toast.dismiss('toast-loading');
      setLoading(false);
      setShowForm(true);
      if (!radio) {
        setRadio('enrolled');
      }
    }
  };

  const fillETIPIFields = data => {
    if (data?.ALUNO) {
      if (data?.ALUNO?.nomePessoa) {
        studentForm.setFieldValue('name', data.ALUNO.nomePessoa);
      }
      if (data?.ALUNO?.Email.replaceAll(' - ', '')) {
        studentForm.setFieldValue('email', data.ALUNO.Email.replaceAll(' - ', ''));
      }
      if (data?.ALUNO?.TelefoneEstudante.replaceAll(/[()\s-]/g, '')) {
        studentForm.setFieldValue('phone', data.ALUNO.TelefoneEstudante.replaceAll(/[()\s-]/g, ''));
      }
      const inepEscola = data?.TURMA[0]?.inepEscola || data?.TURMA?.inepEscola;
      const nomeTurno = data?.TURMA[0]?.nomeTurno || data?.TURMA?.nomeTurno;
      if (inepEscola) {
        const institution = institutions.find(e => e.code === inepEscola);
        if (institution) {
          studentForm.setFieldValue('institution', { value: institution?.id, label: institution?.name });
          setSelectedRadio(false);
        }
      }
      if (nomeTurno) {
        let course = null;
        if (nomeTurno.includes('MANHA')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('manhã'));
        } else if (nomeTurno.includes('TARDE')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('tarde'));
        } else if (nomeTurno.includes('NOITE')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('tarde'));
        }

        if (course) {
          studentForm.setFieldValue('course', course?.id);
        }
      }
      if (data?.ALUNO?.cpf) {
        studentForm.setFieldValue('cpfCnpj', data.ALUNO.cpf);
        setCpfDisabled(true);
      }
      if (data?.ALUNO?.codigoEscolarAluno) {
        studentForm.setFieldValue('ra', data.ALUNO.codigoEscolarAluno);
        setRaDisabled(true);
      }
    }
  };

  const getInstitutions = async () => {
    return await client.fetch('/institutions');
  };

  const getCourses = async () => {
    return await client.fetch('/courses/simple?filter[is_public]=false');
  };

  const getStates = async () => {
    return await client.fetch('/states');
  };

  const getMunicipalities = async () => {
    setLoadingMunicipalities(true);
    const { data, error } = await client.fetch(`/municipalities?filter[state_id]=${studentForm.values.selectedState.value}`);
    if (error) {
      toast.error('Erro ao buscar municipios');
    } else {
      setMunicipalities(data);
    }
    setLoadingMunicipalities(false);
  };

  useEffect(() => {
    if (studentForm.values.selectedState) {
      studentForm.setFieldValue('municipality-id', '');
      getMunicipalities();
    }
  }, [studentForm.values.selectedState]);

  const getClassrooms = async () => {
    setLoading(true);
    const url = appName === 'encceja' ? 'classrooms?simple=true' : `/classrooms?filter[institution_id]=${studentForm.values.institution?.value}&filter[course_id]=${studentForm.values.course}`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
      if (studentForm.values.course === '' && studentForm.values.institution?.value === '') {
        setSingleClassroom(data.length === 1);
      }
      studentForm.setFieldValue('classroom_ids', data[0]?.id);
    }
    setLoading(false);
  };

  useEffect(() => {
    Promise.allSettled([getInstitutions(), getCourses(), getStates()]).then(results => {
      if (Object.keys(results[0].value).includes('error')) {
        toast.error('Erro ao buscar instituições');
      } else {
        setInstitutions(results[0].value.data);
      }
      if (Object.keys(results[1].value).includes('error')) {
        toast.error('Erro ao buscar cursos');
      } else {
        setCourses(results[1].value.data);
      }
      if (Object.keys(results[2].value).includes('error')) {
        toast.error('Erro ao buscar estados');
      } else {
        const sortedSatates = results[2].value.data.sort((a, b) => a.name.localeCompare(b.name));
        setStates(sortedSatates);
      }
    });
  }, []);

  useEffect(() => {
    if (appName !== 'encceja' && studentForm.values.course !== '' && studentForm.values.institution?.value !== '') {
      getClassrooms();
    }
  }, [studentForm.values.course, studentForm.values.institution?.value]);

  useEffect(() => {
    if (appName === 'encceja') getClassrooms();
  }, []);

  useEffect(() => {
    if (session !== null && link) {
      updateUser();
    }
  }, [session, link]);

  useEffect(() => {
    if (session && Object.keys(session).length !== 0) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      window.location.pathname = redirectUrl || '/';
    }
  }, [session]);

  return (
    <div className="new-login">
      <div
        className="new-login__container"
        style={{ alignItems: 'flex-start' }}
      >
        <div
          className="new-login__body"
          style={{ maxWidth: '500px' }}
        >
          <div className="new-login__body-header">
            <div className="new-login__body-logo">
              <img
                alt="Logo Estudologia"
                src={logo}
              />
            </div>
            <div className="new-login__body-title">Para agilizar o seu cadastro, preencha um dos campos a seguir </div>
          </div>

          <div className="new-login__body-form">
            <form
              className="form"
              method="post"
              onSubmit={e => {
                e.preventDefault();
                if (studentForm.values.cpfCnpj !== '' && !validateCPF(studentForm.values.cpfCnpj)) schema.fields;
                studentForm.handleSubmit(e);
              }}
            >
              <div className="container-fields">
                <div>
                  <label
                    className="form__label"
                    htmlFor="cpfCnpj"
                  >
                    {cpfRequired && '*'}CPF
                  </label>
                  <InputMask
                    className="form__control"
                    name="cpfCnpj"
                    id="cpfCnpj"
                    mask="999.999.999-99"
                    placeholder="Digite o número"
                    value={studentForm.values.cpfCnpj}
                    onBlur={handleBlurCPF}
                    onChange={e => studentForm.setFieldValue('cpfCnpj', e.target.value)}
                    disabled={cpfDisabled}
                  />
                  <div className="error-message">{studentForm.touched.cpfCnpj && studentForm.errors.cpfCnpj && <span style={{ color: 'red' }}>{studentForm.errors.cpfCnpj}</span>}</div>
                </div>
                {appName !== 'encceja' && (
                  <>
                    <span className="text">ou</span>
                    <div>
                      <label
                        className="form__label"
                        htmlFor="ra"
                      >
                        RA (Registro Acadêmico)
                      </label>
                      <InputMask
                        disabled={loading || raDisabled}
                        className="form__control"
                        mask="99999999999"
                        name="ra"
                        id="ra"
                        placeholder="Digite o número"
                        value={studentForm.values.ra}
                        onBlur={handleBlurRA}
                        onChange={e => studentForm.setFieldValue('ra', e.target.value)}
                      />
                      {<div className="error-message">{studentForm?.values?.ra?.replaceAll('_', '').length === 11 && studentForm?.errors?.ra}</div>}
                    </div>
                  </>
                )}
              </div>

              {!loading && !singleClassroom && (
                <fieldset className="container-checkbox">
                  <div>
                    <input
                      type="radio"
                      name="enrolled"
                      id="enrolled"
                      value="enrolled"
                      checked={isStudentEnrolled}
                      onChange={() => setRadio('enrolled')}
                    />
                    <label htmlFor="enrolled">Matriculado na escola</label>
                  </div>

                  {institutionsRadioButton?.map(institution => (
                    <div>
                      <input
                        disabled={loading}
                        type="radio"
                        id={`institution-${institution.id}`}
                        name="institution-radio"
                        checked={institution.name === radio}
                        onChange={() => {
                          studentForm.setFieldValue('institution', { value: institution.id, label: institution.name });
                          setSelectedRadio(true);
                          setRadio(institution.name);
                        }}
                      />
                      <label htmlFor={`institution-${institution.id}`}>{institution.name}</label>
                    </div>
                  ))}
                </fieldset>
              )}
              {(radio || showForm) && (
                <>
                  {!singleClassroom && isStudentEnrolled && (
                    <div
                      className="form__row"
                      style={{ marginTop: 0 }}
                    >
                      <label className="form__label">*Escola:</label>
                      <Select
                        isDisabled={loading}
                        value={studentForm.values.institution}
                        openMenuOnFocus
                        className="react-multi-select filter-bar__multi-select u-w-100"
                        classNamePrefix="react-multi-select"
                        placeholder={t('filter.blankLabelAll')}
                        noOptionsMessage={() => 'Sem opções'}
                        options={[
                          { value: '', label: t('filter.blankLabelAll') },
                          ...filteredInstitutions?.map(item => {
                            return { value: item.id, label: item.name };
                          })
                        ]}
                        components={{
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null
                        }}
                        onChange={e => {
                          studentForm.setFieldValue('institution', e);
                          setSelectedRadio(false);
                        }}
                      />
                      {studentForm.touched.institution && studentForm.errors.institution && <span style={{ color: 'red' }}>{studentForm.errors.institution}</span>}
                    </div>
                  )}

                  {!loading && !singleClassroom && (
                    <div className="container-grid-student">
                      <div className="form__Row">
                        <label className="form__label">*{t('textsCommon.course')}:</label>
                        <FilterSelectionBox
                          value={studentForm.values.course}
                          options={courses}
                          onChange={e => studentForm.setFieldValue('course', e.target.value)}
                          className="u-w-100"
                        />
                        {studentForm.touched.course && studentForm.errors.course && <span style={{ color: 'red' }}>{studentForm.errors.course}</span>}
                      </div>
                      <div className="form__Row">
                        <label className="form__label">*{t('textsCommon.class')}:</label>
                        <FilterSelectionBox
                          value={studentForm.values.classroom_ids}
                          onChange={e => studentForm.setFieldValue('classroom_ids', e.target.value)}
                          options={classrooms}
                          disabled={isStudentNoSchool}
                          className="u-w-100"
                        />
                        {studentForm.touched.classroom_ids && studentForm.errors.classroom_ids && <span style={{ color: 'red' }}>{studentForm.errors.classroom_ids}</span>}
                      </div>
                    </div>
                  )}

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="name"
                    >
                      *{t('createAccount.name')}
                    </label>
                    <input
                      disabled={loading}
                      className="form__control disabled"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Digite seu nome"
                      value={studentForm.values.name}
                      onChange={e => studentForm.setFieldValue('name', e.target.value)}
                    />
                    {studentForm.touched.name && studentForm.errors.name && <span style={{ color: 'red' }}>{studentForm.errors.name}</span>}
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="email"
                    >
                      *Email:
                    </label>
                    <input
                      disabled={loading}
                      className="form__control disabled"
                      type="email"
                      name="email"
                      id="email"
                      value={studentForm.values.email}
                      onChange={e => studentForm.setFieldValue('email', e.target.value)}
                    />
                    {studentForm.touched.email && studentForm.errors.email && <span style={{ color: 'red' }}>{studentForm.errors.email}</span>}
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="cellphone"
                    >
                      {t('createAccount.cellphone')}
                    </label>
                    <InputMask
                      disabled={loading}
                      className="form__control"
                      mask="(99) 99999-9999"
                      name="cellphone"
                      id="cellphone"
                      value={studentForm.values.phone}
                      onChange={e => studentForm.setFieldValue('phone', e.target.value)}
                    />
                    {studentForm.touched.phone && studentForm.errors.phone && <span style={{ color: 'red' }}>{studentForm.errors.phone}</span>}
                  </div>

                  {states.length > 0 && (
                    <>
                      <div className="form__row">
                        <label className="form__label">Estado:</label>
                        <Select
                          disabled={loading}
                          value={studentForm.values.selectedState}
                          openMenuOnFocus
                          options={[
                            { value: '', label: 'Selecione um estado' },
                            ...states?.map(item => {
                              return { value: item.id, label: item.name };
                            })
                          ]}
                          className="react-multi-select filter-bar__multi-select u-w-100"
                          classNamePrefix="react-multi-select"
                          placeholder={'Selecione um estado'}
                          noOptionsMessage={() => 'Sem opções'}
                          components={{
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null
                          }}
                          onChange={e => {
                            studentForm.setFieldValue('selectedState', e);
                          }}
                        />
                      </div>

                      <div className="form__row">
                        <label className="form__label">Município:</label>
                        <Select
                          isDisabled={loadingMunicipalities}
                          value={studentForm.values['municipality-id']}
                          openMenuOnFocus
                          options={[
                            { value: '', label: 'Selecione um município' },
                            ...municipalities?.map(item => {
                              return { value: item.id, label: item.name };
                            })
                          ]}
                          className="react-multi-select filter-bar__multi-select u-w-100"
                          classNamePrefix="react-multi-select"
                          placeholder={loadingMunicipalities ? 'Carregando...' : 'Selecione um município'}
                          noOptionsMessage={() => 'Sem opções'}
                          components={{
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null
                          }}
                          onChange={e => {
                            studentForm.setFieldValue('municipality-id', e);
                          }}
                        />
                        {studentForm.touched['municipality-id'] && studentForm.errors['municipality-id'] && <span style={{ color: 'red' }}>{studentForm.errors['municipality-id']}</span>}
                      </div>
                    </>
                  )}

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="password"
                    >
                      *{i18n.t('login.password')}
                    </label>
                    <PasswordStrength
                      show={showPasswordStrength}
                      password={studentForm.values.password}
                      validPassword={setPasswordIsStrong}
                    >
                      <div className="form__row--password">
                        <input
                          disabled={loading}
                          title={i18n.t('profile.typeYourPass')}
                          className="form__control "
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          id="password"
                          onChange={e => studentForm.setFieldValue('password', e.target.value)}
                          onFocus={() => {
                            setShowPasswordStrength(true);
                            passwordRef.current.removeAttribute('readOnly');
                          }}
                          onBlur={() => {
                            setShowPasswordStrength(false);
                            passwordRef.current.setAttribute('readOnly', '');
                          }}
                          ref={passwordRef}
                          autoComplete="off"
                          readOnly
                        />
                        <span onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FiEyeOff /> : <FiEye />}</span>
                      </div>
                    </PasswordStrength>
                    {studentForm.touched.password && studentForm.errors.password && <span style={{ color: 'red' }}>{studentForm.errors.password}</span>}
                  </div>

                  {appName === 'encceja' && (
                    <div
                      className="form__row"
                      style={{ alignItems: 'center' }}
                    >
                      <label
                        className="form__row back-login label-terms"
                        htmlFor="isEnroled"
                      >
                        <input
                          type="radio"
                          name="isEnroled"
                          id="isEnroled"
                          checked={studentForm.values['is-enrolled-in-seduc']}
                          onClick={() => studentForm.setFieldValue('is-enrolled-in-seduc', !studentForm.values['is-enrolled-in-seduc'])}
                        />
                        <span>Já sou matriculado na SEDUC</span>
                      </label>
                    </div>
                  )}

                  <label
                    className="form__row back-login label-terms"
                    htmlFor="agreeTerms"
                  >
                    <input
                      type="checkbox"
                      name="agreeTerms"
                      id="agreeTerms"
                      checked={agreeTerms}
                      onChange={() => setAgreeTerms(!agreeTerms)}
                    />
                    {t('login.termsText')}{' '}
                    <a
                      href="/termos-de-uso"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('login.userTerm')}{' '}
                    </a>
                    {t('exams.and')}{' '}
                    <a
                      href="/politica-de-privacidade"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('login.privacyTerm')}
                    </a>
                    .
                  </label>

                  <div className="form__row">
                    <input
                      type="submit"
                      value={t('createAccount.buttonCreateAccount')}
                      disabled={!passwordIsStrong}
                    />
                  </div>
                </>
              )}

              <Link
                to={`/login`}
                className="form__row rember-link m-0"
              >
                {t('createAccount.loginWithMyAccount')}
              </Link>
            </form>
          </div>
        </div>
      </div>
      <div className="new-login__image">
        <div
          className="new-login__login-image"
          style={{ backgroundImage: `url(${backimage})` }}
        ></div>
      </div>
    </div>
  );
}
