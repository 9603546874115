import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import moment from 'moment';
import reorder from '../../../utils/reorder';

export default function DataGridGenerated({ report, setReport }) {
  const [arrows, setArrows] = useState({
    date: 'asc',
    name: 'asc',
    email: 'asc',
    institution: 'asc',
    inep: 'asc',
    gre: 'asc',
    lesson: 'asc'
  });
  
  const handleReorder = (array, param, order, arrow) => {
    if (arrow === 'date') {
      const newData = reorder(array, param, order, 'date');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'name') {
      const newData = reorder(array, param, order, 'name');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'email') {
      const newData = reorder(array, param, order, 'name');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'institution') {
      const newData = reorder(array, param, order, 'name');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'inep') {
      const newData = reorder(array, param, order, 'number');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'gre') {
      const newData = reorder(array, param, order, 'name');
      setReport({ ...report, logs: newData });
    }
    if (arrow === 'lesson') {
      const newData = reorder(array, param, order, 'name');
      setReport({ ...report, logs: newData });
    }

    setArrows({ ...arrows, [arrow]: order });
  };
  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm "
              style={{ width: '120px' }}
            >
              Data
              {arrows.date === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'access-date', 'desc', 'date');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'access-date', 'asc', 'date');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Aula
              {arrows.lesson === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'live-classroom-title', 'desc', 'lesson');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'live-classroom-title', 'asc', 'lesson');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Aluno
              {arrows.name === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'name', 'desc', 'name');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'name', 'asc', 'name');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              E-mail
              {arrows.email === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'email', 'desc', 'email');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'email', 'asc', 'email');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
          
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Escola
              {arrows.institution === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'institution', 'desc', 'institution');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'institution', 'asc', 'institution');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '100px' }}
            >
              INEP
              {arrows.inep === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'inep', 'desc', 'inep');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'inep', 'asc', 'inep');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              GRE
              {arrows.gre === 'asc' ? (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronDown
                  onClick={() => {
                    handleReorder(report?.logs, 'gre', 'desc', 'gre');
                  }}
                  className="card__icon"
                />
              </span>
            ) : (
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                style={{ marginLeft: '4px' }}
              >
                <FiChevronUp
                  onClick={() => {
                    handleReorder(report?.logs, 'gre', 'asc', 'gre');
                  }}
                  className="card__icon"
                />
              </span>
            )}
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {report?.logs?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{moment(item?.['access-date']).format('DD/MM/YYYY')}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.['live-classroom-title']}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.name}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.email}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.institution}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.inep}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.gre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
