import React, { useState, useEffect } from 'react';
import Modal, { SimpleModal } from 'app/components/Modal';
import { FiZap } from 'react-icons/fi';
import { ReactComponent as MedalSuccess } from 'app/images/icons/medal-success.svg';
import { ReactComponent as MedalWarning } from 'app/images/icons/medal-warning.svg';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectCards } from 'swiper/modules';
import Flashcard from './Flashcard';

export default function FlashcardGuideModal(props) {
  const { show, onClose, flashcardData, onChangePercentage, percentage, flashcardsAccepted } = props;

  const allQuestionsQuantity = flashcardData?.length;

  const client = useClient();
  const { t } = useTranslation();
  const [isTurned, setIsTurned] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [contRight, setContRight] = useState(flashcardsAccepted?.length ?? 0);

  const [flashAccepted, setFlashAccepted] = useState(flashcardsAccepted);
  const [responsePercentage, setResponsePercentage] = useState(percentage ?? 0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [correctQuestionsIndex, setCorrectQuestionsIndex] = useState([]);

  const [advice, setAdvice] = useState(false);

  async function handleSubmitQuestion(question, knowAnswer, acceptedAnswer, answered) {
    setIsSubmitting(true);
    const currentFlashcardUserId = question.user_flashcard_id;
    const currentFlashcardId = question.id;

    const { data, error } = await client.mutate(
      `/user_flashcards/${currentFlashcardUserId}`,
      {
        'user-answer-flashcards-attributes': [
          {
            'flashcard-id': currentFlashcardId,
            'know-answer': knowAnswer,
            'accepted-answer': acceptedAnswer,
            answered: answered
          }
        ]
      },
      { method: 'PUT' }
    );

    const dataPercentage = ((contRight + 1) / allQuestionsQuantity) * 100;
    setFlashAccepted(data => [...data, flashcardData[currentQuestion]]);
    setResponsePercentage(prev => (prev = dataPercentage));
    onChangePercentage(dataPercentage?.toFixed(0));

    if (error) {
      setIsSubmitting(false);
      toast.error(t('toast.errorOnSendAnswerFlashCard'));
      return error;
    }

    setIsSubmitting(false);
  }

  useEffect(() => {
    if (responsePercentage === 100) {
      setAdvice(true);
    } else {
      setAdvice(false);
    }
  }, [responsePercentage]);

  useEffect(() => {
    setCurrentQuestion(contRight - 1);
  }, []);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setCorrectQuestionsIndex([]);
    }, 300);
  };
  return (
    <SimpleModal
      show={show}
      onClose={() => handleClose()}
      contentClassName="simple-modal__content--lg"
    >
      <div className="flashcard-modal-guide">
        <div className="card__progress">
          {/* <div className="card__progress-container">
            <div
              className="card__progress-bar"
              role="progressbar"
              style={{ width: `${responsePercentage}%` }}
              aria-valuenow={responsePercentage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div> */}
          {/* <div className="card__progress-text">{`${parseInt(responsePercentage)}%`}</div> */}
        </div>

        {flashcardData?.length > flashAccepted?.length && !advice ? (
          <div className="flashcard-wrapper">
            <div className="flashcard-wrapper__inner">
              <div className="flashcard-wrapper__header">
                <h3 className="flashcard-wrapper__title">{t('flashCard.review')}</h3>
              </div>

              <Swiper
                slidesPerView={1}
                modules={[EffectCards]}
                effect="cards"
                loop
                noSwipingClass="my-no-swiping"
              >
                {flashcardData
                  .filter(flash => !flashAccepted.map(flash => flash['flashcard-id']).includes(flash.id))
                  .map((question, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <Flashcard
                          setCorrectQuestionsIndex={setCorrectQuestionsIndex}
                          correctQuestionsIndex={correctQuestionsIndex}
                          setContRight={setContRight}
                          contRight={contRight}
                          setCurrentQuestion={setCurrentQuestion}
                          currentQuestion={currentQuestion}
                          cicleQuestions={flashcardData}
                          responsePercentage={responsePercentage}
                          handleSubmitQuestion={handleSubmitQuestion}
                          question={question}
                          setIsTurned={setIsTurned}
                          isTurned={isTurned}
                          isSubmitting={isSubmitting}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="flashcard-success">
            <div className="flashcard-success__inner">
              <MedalSuccess className="flashcard-success__medal" />

              <h3 className="flashcard-success__title">{t('flashCard.successTitle')}</h3>

              <p className="flashcard-success__description">{t('flashCard.successDescription')}</p>

              <button
                className="btn btn--wide btn--primary"
                onClick={onClose}
              >
                {t('flashCard.buttonReturnStudyPlan')}
              </button>
            </div>
          </div>
        )}
      </div>
    </SimpleModal>
  );
}
