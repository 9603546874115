export default function reorder(arr, param, order, type) {
  const reorderedArr = [...arr];

  reorderedArr?.sort((a, b) => {
    if (a[param] && b[param]) {
      if (order === 'asc') {
        if (type === 'date') {
          return new Date(a[param]) - new Date(b[param]);
        } else if (type === 'name') {
          return a[param].localeCompare(b[param]);
        } else {
          return a[param] - b[param];
        }
      } else {
        if (type === 'date') {
          return new Date(b[param]) - new Date(a[param]);
        } else if (type === 'name') {
          return b[param].localeCompare(a[param]);
        } else {
          return b[param] - a[param];
        }
      }
    } else {
      return 0
    }
  });

  return reorderedArr;
}
