import React from 'react';
import GuideCard from '../GuideCard';
import flag from './img/flag.svg';
import trophy from './img/trophy.svg';
import rocket from './img/rocket.svg';
import relaxation from './img/relaxation.svg';
import { TIAResults } from 'app/components/TIA';
import moment from 'moment';

export default function GuideTimeline({ studyGuide }) {
  const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta'];

  const tiaResultsDate = moment(studyGuide?.['daily-message']?.['created-at']).format('YYYY-MM-DD');
  const tiaResultsMessage = studyGuide?.['daily-message']?.body;

  const videosByDate = studyGuide['study-plan-videos'].reduce((acc, video) => {
    const formattedDate = moment(video.date).format('YYYY-MM-DD');
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(video);
    return acc;
  }, {});

  const sortedDates = Object.keys(videosByDate).sort();

  let firstUndoneFound = false;

  return (
    <ul className="guide-timeline">
      {sortedDates.map((date, index) => {
        const currentDate = moment(date).format('DD [de] MMMM [de] YYYY');
        const dayOfWeek = daysOfWeek[index % 5];

        const today = moment().format('YYYY-MM-DD');

        return (
          <li
            className="guide-timeline__day"
            key={index}
          >
            <div
              id={dayOfWeek.toLowerCase()}
              className="guide-timeline__header"
            >
              <img
                className="guide-timeline__icon"
                src={flag}
                alt="bandeira"
              />
              <h2 className="guide-timeline__title">Vamos começar mais um dia com todo o gás</h2>
              <p className="guide-timeline__date">{`${dayOfWeek}, ${currentDate}`}</p>
            </div>

            <div className="guide-timeline__cards">
              {tiaResultsDate === date && dayOfWeek !== 'Segunda' && (
                <>
                  {tiaResultsMessage && <TIAResults message={tiaResultsMessage} />}
                  <br />
                  <br />
                </>
              )}

              {videosByDate[date].map((video, idx) => {
                const nextToBeDone = !firstUndoneFound && video.done === false;
                if (nextToBeDone) {
                  firstUndoneFound = true;
                }
                return (
                  <GuideCard
                    key={idx}
                    done={video.done}
                    title={video.taxonomy.name}
                    video={video}
                    guide_index={idx}
                    canBeClicked={date <= today}
                    isCurrent={nextToBeDone}
                  />
                );
              })}
            </div>

            {index < 4 ? (
              <div className="guide-timeline__footer">
                <img
                  className="guide-timeline__icon"
                  src={trophy}
                  alt="troféu"
                />
                <h2 className="guide-timeline__title">Chegamos ao final da {dayOfWeek.toLowerCase()}-feira.</h2>
              </div>
            ) : (
              <div className="guide-timeline__footer">
                <img
                  className="guide-timeline__icon"
                  src={trophy}
                  alt="troféu"
                />
                <h2 className="guide-timeline__title">Parabéns. Você finalizou mais uma semana</h2>
              </div>
            )}
          </li>
        );
      })}

      <li className="guide-timeline__day guide-timeline__day--pause">
        <div className="guide-timeline__body">
          <div className="guide-timeline__content">
            <h2 className="guide-timeline__title">Oba, o final de semana chegou</h2>
            <p className="guide-timeline__text">
              Aproveite para recarregar as energias.
              <br /> Nos vemos na próxima semana!
            </p>
          </div>
          <img
            className="guide-timeline__img"
            src={relaxation}
            alt="relaxamento"
          />
        </div>
      </li>
    </ul>
  );
}
